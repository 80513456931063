.photo {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 13px;
    background-size: cover;
    background-position: center; }
.photo_wrap {
    display: flex;
    justify-content: center; }
.name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #373737;
    margin-bottom: 8px;
    text-align: center; }
.job {
    font-size: 12px;
    line-height: 14px;
    color: #A9A9A9;
    margin-bottom: 33px;
    text-align: center; }
.features {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: calc(-18px + 56px); }
.features__item {
    margin-right: 18px;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #373737;
    svg {
        margin-right: 6px;
        flex-shrink: 0; }
    &:last-child {
        margin-right: 0; } }
.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0; } } }

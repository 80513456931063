@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.menu {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 69.64px);
    height: calc(var(--vh, 1vh) * 100 - 69.64px);
    z-index: 100;
    background: rgba(40, 40, 40, 0.6);
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s;
    @include sm {
        top: 64px;
        height: calc(100vh - 64px);
        height: calc(var(--vh, 1vh) * 100 - 64px);
        border-top: 1px solid #F0F0F0; } }
.menu__content {
    width: 303px;
    position: relative;
    transform: translate(-100%, 0);
    transition: transform .4s ease;
    height: calc(100vh - 69.64px);
    height: calc(var(--vh, 1vh) * 100 - 69.64px);
    overflow: auto;
    @include sm {
        height: calc(100vh - 64px);
        height: calc(var(--vh, 1vh) * 100 - 64px);
        width: 100%; } }

.menu.active {
    opacity: 1;
    pointer-events: auto;
    .menu__content {
        transform: translate(0, 0); } }

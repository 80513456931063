@mixin xs {
    @media (max-width: 374px) {
        @content; } }

@mixin sm {
    @media (max-width: 767px) {
        @content; } }

@mixin md {
    @media (max-width: 991px) {
        @content; } }

@mixin lg {
    @media (max-width: 1199px) {
        @content; } }


.popup {
    background: #F5F5F5; }
.image_wrap {
    text-align: center;
    margin-bottom: 51px; }
.image {
    display: inline-block;
    position: relative;
    img {
        display: block; } }
.image__count {
    position: absolute;
    right: 32%;
    top: 26%;
    width: 52px;
    height: 52px;
    background: #F5F5F5;
    border-radius: 50%;
    line-height: 52px;
    text-align: center;
    font-size: 40px; }

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0; } } }

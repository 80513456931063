@import "../../../assets/styles/variables";

.burger {
    padding: 6px 3px; }
.burger span {
    display: block;
    width: 18px;
    height: 2px;
    background: #373737;
    margin-bottom: 3px;
    border-radius: 25px;
    position: relative;
    opacity: 1;
    transition: transform .3s, opacity .3s, color .3s;
    &:last-child {
        margin-bottom: 0; } }

.burger--active {
    span:nth-child(3) {
        opacity: 0; }
    span:nth-child(1) {
        transform: rotate(45deg);
        bottom: -5px; }
    span:nth-child(2) {
        transform: rotate(-45deg); }
    span {
        background: $primary; } }

@import 'normalize.css/normalize';
@import 'react-loading-skeleton/dist/skeleton.css';
@import './variables';
@import './mixins';
@import './fonts';

body {
  margin: 0;
  font-family: 'HelveticaNeueCyr', sans-serif;
  color: $black;
  position: relative; }

* {
  box-sizing: border-box; }

.hidden_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0; }

button {
  border: 0; }

h1, .h1 {
  font-size: 22px;
  line-height: 22px;
  margin: 0; }
h2, .h2 {
  font-size: 18px;
  line-height: 22px;
  margin: 0; }

.link {
  text-decoration: none;
  color: $primary;
  transition: color .2s;
  cursor: pointer;
  padding: 0;
  background: transparent;
  &:hover {
    color: $primaryDark; } }

.primary {
  color: $primary; }

.text_center {
  text-align: center; }

img {
  max-width: 100%; }

.hide_lg {
  @include lg {
    display: none; } }
.hide_sm {
  @include sm {
    display: none; } }

.fs14 {
  font-size: 14px;
  line-height: 16px; }

.bold {
  font-weight: 500; }

.mb40 {
  margin-bottom: 40px; }

input, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  background-clip: padding-box;
  border: 0;
  border-radius: 0; }

.nowrap {
  white-space: nowrap; }

@media (min-width: 1200px) {
  .show_lg {
    display: none; } }
@media (min-width: 768px) {
  .show_sm {
    display: none; } }

.no_data_margin {
  margin: 100px 0;
  @include sm {
    margin: 80px 0; } }

div.spinner {
    position: relative;
    width: 30.5px;
    height: 30.5px;
    display: inline-block;
    padding: 10px;
    border-radius: 10px; }

div.spinner div {
    width: 9%;
    height: 22%;
    background: #FFF;
    position: absolute;
    left: 49%;
    top: 43%;
    opacity: 0;
    -webkit-border-radius: 50px;
    -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.2);
    -webkit-animation: fade 1s linear infinite; }

@keyframes fade {
  from {
    opacity: 1; }
  to {
    opacity: 0.25; } }

div.spinner div.bar1 {
    -webkit-transform: rotate(0deg) translate(0, -130%);
    -webkit-animation-delay: 0s; }

div.spinner div.bar3 {
    -webkit-transform: rotate(45deg) translate(0, -130%);
    -webkit-animation-delay: -0.833s; }

div.spinner div.bar4 {
    -webkit-transform: rotate(90deg) translate(0, -130%);
    -webkit-animation-delay: -0.7497s; }

div.spinner div.bar6 {
    -webkit-transform: rotate(135deg) translate(0, -130%);
    -webkit-animation-delay: -0.5837s; }

div.spinner div.bar7 {
    -webkit-transform: rotate(180deg) translate(0, -130%);
    -webkit-animation-delay: -0.5s; }

div.spinner div.bar9 {
    -webkit-transform: rotate(225deg) translate(0, -130%);
    -webkit-animation-delay: -0.333s; }

div.spinner div.bar10 {
    -webkit-transform: rotate(270deg) translate(0, -130%);
    -webkit-animation-delay: -0.2497s; }

div.spinner div.bar12 {
    -webkit-transform: rotate(315deg) translate(0, -130%);
    -webkit-animation-delay: -0.0833s; }

@import "../../assets/styles/mixins";

.layout {
    background: #F0F0F0;
    min-height: calc(var(--vh, 1h) * 100);
    padding-top: 101.64px;
    @include lg {
        padding-top: 93.64px; }
    @include sm {
        padding-top: 88px; } }
.layout__container {
    width: 100%;
    max-width: 1440px;
    padding: 0 24px;
    padding-bottom: 32px;
    margin: 0 auto;
    @include lg {
        padding: 0; } }
.layout__inner {
    display: flex; }
.layout__sidebar {
    width: 330px;
    margin-right: 24px;
    flex-shrink: 0;
    position: relative;
    @include lg {
        display: none; } }
.layout__content {
    width: calc(100% - 24px - 330px);
    @include lg {
        background: #fff;
        width: 100%;
        min-height: calc(var(--vh, 1vh) * 100 - 93.64px);
        padding: 24px 20px; }
    @include sm {
        padding: 16px 20px; } }
.layout__menu {
    overflow: auto;
    border-radius: 4px;
    height: calc(var(--vh, 1vh) * 100 - 32px - 101.64px);
    max-height: 766px;
    position: sticky;
    top: 101.64px; }

.layout--no_sidebar {
    .layout__content {
        width: 100%; } }

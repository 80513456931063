@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.user {
    display: flex;
    align-items: center;
    position: relative; }
.user__image {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-right: 8px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
.user__title {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 2px;
    display: block; }
.user__description {
    color: #A9A9A9;
    font-size: 12px;
    line-height: 14px;
    display: block; }
.user__text {
    margin-right: 8px;
    text-decoration: none;
    color: $black;
    @include lg {
        display: none; }
    &:last-child {
        margin-right: 0; } }
.user__toggle {
    cursor: pointer;
    padding: 5px 3px;
    transition: transform .3s; }
.user__toggle_icon {
    display: block; }

.user_menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 105;
    top: calc(100% + 19px);
    right: -5px;
    width: calc(100% + 5px);
    min-width: 150px;
    background: #FFFFFF;
    box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 16px;
    opacity: 0;
    pointer-events: none;
    transform: translate(0, 20px);
    transition: opacity .3s, transform .3s;
    @include sm {
        top: calc(100% + 15px); }
    &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        right: 8px;
        top: -5px;
        background: #fff;
        transform: rotate(45deg); } }
.user_menu__item {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 14px;
    cursor: pointer;
    transition: color .2s;
    &:last-child {
        margin-bottom: 0; }
    &:hover {
        color: $primary; } }
.user_menu__item_icon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    flex-shrink: 0; }

.user--menu-active {
    .user_menu {
        opacity: 1;
        pointer-events: auto;
        transform: translate(0); }
    .user__toggle {
        transform: rotate(180deg); } }

@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.header {
    background: #fff;
    padding: 16px 0;
    margin-bottom: 32px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    @include lg {
        margin-bottom: 24px; } }
.header__container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 32px;
    @include lg {
        padding-left: 23px;
        padding-right: 23px; }
    @include sm {
        padding-left: 20px;
        padding-right: 20px; } }
.header__inner {
    display: flex;
    align-items: center; }
.header__burger {
    display: none;
    @include lg {
        display: block;
        margin-right: 24px; }
    @include sm {
        margin-right: 16px; } }
.header__logo {
    margin-right: 209px;
    @include lg {
        margin-right: 74px; }
    @include sm {
        margin-right: 20px; }
    img {
        max-width: 161px;
        display: block;
        @include sm {
            max-width: 129px; }
        @include xs {
            max-width: 110px; } } }
.header__date {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    @include sm {
        display: none; } }
.header__weekday {
    color: rgba(55, 55, 55, 0.6);
    text-transform: capitalize; }
.header__right {
    margin-left: auto;
    display: flex;
    align-items: center; }
.header__action_list {
    display: none;
    @include lg {
        margin-right: 38px;
        display: flex; }
    @include sm {
        margin-right: 16px; } }
.header__action_item {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $black;
    text-decoration: none; }
.header__action_icon {
    display: block;
    margin-right: 7px;
    &:last-child {
        margin-right: 0; } }

@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.popup {
    pointer-events: none;
    opacity: 0;
    z-index: 200;
    position: relative;
    &.active {
        opacity: 1;
        pointer-events: auto;
        .popup__main {
            animation: fadeIn .6s ease forwards; } } }
.popup__inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: rgba(0, 0, 0, 0.4);
    text-align: center;
    overflow: auto;
    &:after {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle; } }
.popup__main {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    width: calc(100% - 40px);
    max-width: 663px;
    background: #fff;
    padding: 56px 78px;
    position: relative;
    border-radius: 4px;
    @include lg {
        max-width: 600px;
        padding: 56px 47px; }
    @include sm {
        padding: 48px 25px; } }
.popup__cross {
    width: 24px;
    height: 24px;
    background: #F0F0F0;
    border-radius: 8px;
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include sm {
        top: 16px;
        right: 16px; }
    svg rect {
        fill: $primary;
        transition: fill .2s; }
    &:hover {
        svg rect {
            fill: $primaryDark; } } }
.popup__head {
    margin-bottom: 50px;
    @include sm {
        margin-bottom: 42px; } }
.popup__title {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
    &:last-child {
        margin-bottom: 0; }
    @include lg {
        font-size: 18px;
        line-height: 22px;
        max-width: 392px; }
    @include sm {
        font-size: 16px;
        line-height: 19px;
        max-width: 214px; } }
.popup__subtitle {
    text-align: center; }

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translate(0, 200px); }
    100% {
        opacity: 1;
        transform: translate(0, 0); } }


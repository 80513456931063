@import "../../assets/styles/mixins";

.layout {
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center; }
.layout__container {
    width: 100%;
    max-width: 546px;
    margin: 0 auto;
    padding: 0 20px; }
.layout__logo_wrap {
    text-align: center;
    margin-bottom: 11.9vh;
    @include sm {
        margin-bottom: 74px;
        width: 160px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 68px; } }
.layout__fixed_icon {
    position: absolute;
    right: 46px;
    bottom: 46px;
    z-index: -1;
    @include lg {
        width: 117px;
        height: 135px; }
    @include sm {
        display: none; } }

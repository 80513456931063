@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.menu {
    background: #fff;
    padding: 32px 24px 24px;
    display: flex;
    flex-direction: column;
    @include lg {
        padding: 48px 20px 24px; }
    @include sm {
        padding: 42px 20px 16px; } }
.menu__list {
    margin-bottom: 24px; }
.menu__item {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    color: #373737;
    text-decoration: none;
    &.active {
        pointer-events: none; }
    &.active, &:hover {
        .menu__item_icon {
            background: $primary;
            svg path:not([fill]) {
                stroke: #fff; }
            svg path[fill] {
                fill: #fff; } } }
    &:last-child {
        margin-bottom: 0; } }
.menu__item_icon {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    background: #F0F0F0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background .2s;
    svg {
        transition: fill .2s, stroke .2s; } }
.menu__item_text {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    @include sm {
        font-size: 16px; } }
.menu__item_notification {
    margin-left: auto; }
.menu__item_time {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    svg {
        margin-right: 4px; } }
.menu__bottom {
    margin-top: auto; }
.menu__bottom_link {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    color: $black;
    margin-bottom: 4px;
    transition: color .2s;
    @include sm {
        display: flex;
        margin-bottom: 16px;
        padding: 16px 20px 0;
        margin-left: -20px;
        margin-right: -20px;
        border-top: 1px solid #EAEAEA; }
    &:hover {
        color: $primary; }
    svg {
        margin-left: 10px; } }
.menu__footer {
    display: block;
    border-top: 1px solid #EAEAEA;
    text-decoration: none;
    color: $black;
    padding-top: 24px;
    transition: color .2s;
    @include sm {
        border-top: 0;
        padding-top: 0; }
    &:hover {
        color: $primary; } }
.menu__footer_title {
    display: block;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    @include lg {
        font-size: 12px;
        line-height: 14px; }
    @include sm {
        margin-bottom: 12px; } }
.menu__footer_desc {
    font-size: 12px;
    line-height: 14px;
    color: #A9A9A9; }

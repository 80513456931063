@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('HelveticaNeueCyr-Roman.eot');
    src: url('HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
        url('HelveticaNeueCyr-Roman.woff2') format('woff2'),
        url('HelveticaNeueCyr-Roman.woff') format('woff'),
        url('HelveticaNeueCyr-Roman.ttf') format('truetype'),
        url('HelveticaNeueCyr-Roman.svg#HelveticaNeueCyr-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('HelveticaNeueCyr-Medium.eot');
    src: url('HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
        url('HelveticaNeueCyr-Medium.woff2') format('woff2'),
        url('HelveticaNeueCyr-Medium.woff') format('woff'),
        url('HelveticaNeueCyr-Medium.ttf') format('truetype'),
        url('HelveticaNeueCyr-Medium.svg#HelveticaNeueCyr-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@import "../../../assets/styles/variables";

.button {
    height: 48px;
    position: relative;
    padding: 0 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    background: $primary;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 550;
    cursor: pointer;
    text-decoration: none;
    transition: background .2s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: $primaryDark; } }

.button__spinner {
    vertical-align: middle;
    flex-shrink: 0;
    margin-left: 8px; }

.button--loading {
    pointer-events: none;
    justify-content: space-between; }

.button--grey {
    background: #F4F4F4;
    color: $black;
    &:hover {
        background: #e3e3e3; } }

.button--disabled {
    pointer-events: none;
    background-color: #BDBDBD; }
